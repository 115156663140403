import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from 'angularfire2/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { ThrowStmt } from '@angular/compiler';
import { Articulo } from '../models/articulo';
import { DatosService } from './datos.service';
@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  
  
  private articuloCollection: AngularFirestoreCollection<Articulo>;
  private articulos: Observable<Articulo[]>;

  private catsCollection: AngularFirestoreCollection<any>;
  private cats: Observable<any[]>;
  private slider: Observable<any[]>;
  private links: Observable<any[]>;
  constructor( private db: AngularFirestore,
               private ciaServ: DatosService ) {

   
    
  }

  rebuild() {
   
  }

 
  getArticulos() {
    return this.articulos = this.db.collection<Articulo>('PRODUCTOS', (ref) =>
    ref).valueChanges();
  }
  getCats() {
    return this.cats = this.db.collection<any>('CATEGORIAS', (ref) =>
    ref).valueChanges();
  }

  getSlider() {
    return this.cats = this.db.collection<any>('SLIDER', (ref) =>
    ref).valueChanges();
  }
  getLinks() {
    return this.cats = this.db.collection<any>('LINKS', (ref) =>
    ref).valueChanges();
  }

  getFotos() {
    return this.cats = this.db.collection<any>('FOTOS', (ref) =>
    ref).valueChanges();
  }
  getArticulo( id: string ) {
    this.articuloCollection = this.db.collection<Articulo>('PRODUCTOS');
    return this.articuloCollection.doc<Articulo>(id).valueChanges();
  }

  addArticulo( articulo: Articulo) {
    articulo.ar_codigo = this.db.createId();
    this.articuloCollection = this.db.collection<Articulo>('PRODUCTOS');
    return this.articuloCollection.doc(articulo.ar_codigo).set(articulo);
  }

  updateArticulo( articulo: Articulo ) {
    this.articuloCollection = this.db.collection<Articulo>('PRODUCTOS');
    return this.articuloCollection.doc(articulo.ar_codigo).update(articulo);
  }
  

  removeArticulo( id: string) {
    this.articuloCollection = this.db.collection<Articulo>('PRODUCTOS');
    return this.articuloCollection.doc(id).delete();
  }
}
