import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../../services/carrito.service';
import { ActionSheetController, ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'myApp-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit {

  constructor(public carritoService: CarritoService,
              private actionSheetCtrl: ActionSheetController,
              private nav: NavController,
              private modal2: ModalController) { }

  ngOnInit() {
    console.log(this.carritoService.carrito)
  }



  cerrar(){
    this.modal2.dismiss();
  }

  pagar(){
    this.nav.navigateRoot(['realizar-pedido/']);
    this.modal2.dismiss();
  }

  async borrar(art) {
    const actionSheet = await this.actionSheetCtrl.create({
      mode: 'ios',
      header: 'Opciones',
      buttons: [
      {
        text: 'Borrar Linea',
        icon: 'trash',
        handler: () => {
          let index = this.carritoService.carrito.findIndex(producto => producto.ar_codigo == art.ar_codigo)
          this.carritoService.carrito.splice(index,1);
          this.carritoService.totalizar();
         
        }
      }, {
        text: 'Cancelar',
        icon: 'close',
        role: 'cancel',
        handler: () => {}
      }]
    });
    await actionSheet.present();
  }
}
