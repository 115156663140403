import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  carrito = [
    
  ]
  total = 0;
  usuario: any;
  tc = 545
  constructor() { }


  async totalizar(){
    this.total = 0;
    this.carrito.forEach(data =>{
      this.total += data.ar_precio * data.ar_cantidad;
    })
  }
}
