import { Injectable } from '@angular/core';
import { Usuario } from '../models/usuario';

@Injectable({
  providedIn: 'root'
})
export class DatosService {
  public links: any;
  constructor() { }
  public usuario: Usuario;
  public categorias: any;
  public logged = false;
 
}
