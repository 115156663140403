import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage";

import { AngularFireModule } from "angularfire2";
import { AngularFirestoreModule } from "angularfire2/firestore";

import { AngularFireStorageModule } from "@angular/fire/storage";
import { environment } from "src/environments/environment";
import { ArticulosComponent } from "./components/articulos/articulos.component";
import { FormsModule } from "@angular/forms";
import { CommonModule, DatePipe } from "@angular/common";
import { SidemenuComponent } from "./components/sidemenu/sidemenu.component";
import { ModalArtComponent } from "./components/modal-art/modal-art.component";

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { SidemenuAdminComponent } from "./components/sidemenu-admin/sidemenu-admin.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { ModalAgregarComponent } from "./components/modal-agregar/modal-agregar.component";
import { DetallePedidoComponent } from "./components/detalle-pedido/detalle-pedido.component";
import { PrendaClienteComponent } from './components/prenda-cliente/prenda-cliente.component';
import { CarritoComponent } from './components/carrito/carrito.component';
import { SideClienteComponent } from './components/side-cliente/side-cliente.component';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MapComponent } from "./components/map/map.component";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LoginComponent } from "./components/login/login.component";



@NgModule({
  declarations: [
    AppComponent,
    SideClienteComponent,
    ArticulosComponent,
    CarritoComponent,
    PrendaClienteComponent,
    SidemenuComponent,
    ModalArtComponent,
    SidemenuAdminComponent,
    ModalAgregarComponent,
    DetallePedidoComponent,
    MapComponent,
    LoginComponent,

  ],
  entryComponents: [,DetallePedidoComponent,ModalAgregarComponent,SidemenuAdminComponent,ArticulosComponent, SidemenuComponent, ModalArtComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,

    AngularFireStorageModule,
    CommonModule,
    HttpClientModule,
    BackButtonDisableModule.forRoot(),
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  exports: [ArticulosComponent],
  providers: [
    InAppBrowser,
    DatePipe,
    Geolocation,
    HttpClient,
    StatusBar,
    SplashScreen,
    IonicStorageModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
