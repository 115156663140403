import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ContactoComponent } from '../contacto/contacto.component';
import { CarritoService } from 'src/app/services/carrito.service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'myApp-side-cliente',
  templateUrl: './side-cliente.component.html',
  styleUrls: ['./side-cliente.component.scss'],
})
export class SideClienteComponent implements OnInit {

  constructor(private modal: ModalController,
              private nav: NavController,
              public carritoServ: CarritoService,
              private modal2: ModalController) { }

  ngOnInit() {}
  

  async contacto() {
    this.cerrar();
    const modal = await this.modal2.create({
      component: ContactoComponent,
      cssClass: '',
      componentProps: {
      
    
      }
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
     
    }
  }


  home(){
    this.nav.navigateRoot('home');
    this.modal.dismiss();
  }

  pedidos(){
    this.nav.navigateRoot('mis-pedidos');
    this.modal.dismiss();
  }

  cerrar(){
    this.modal.dismiss();
  }

  async login() {
    this.cerrar();
    const modal = await this.modal2.create({
      component: LoginComponent,
      cssClass: '',
      componentProps: {
      
    
      }
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
     
    }
  }
}
