export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyCfxNv5z4WPTR4EG9kikBi6H4LkSYqkuqQ",
  authDomain: "repuestos-rq.firebaseapp.com",
  projectId: "repuestos-rq",
  storageBucket: "repuestos-rq.appspot.com",
  messagingSenderId: "485498236884",
  appId: "1:485498236884:web:28ed050705550abc2cb041"
  }
};
