import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  usuario = 'Rodrigo'
  constructor(private modal: ModalController,
         
              private nav: NavController) { }

  ngOnInit() {}



  cerrar(){
    this.modal.dismiss();
  }

  navInicio(){
    this.nav.navigateRoot(['home']);
    this.modal.dismiss();
  }

}
