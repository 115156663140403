import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ArticulosService } from 'src/app/services/articulos.service';
import { DatosService } from 'src/app/services/datos.service';
import { AngularFireStorage } from '@angular/fire/storage';



@Component({
  selector: 'app-modal-art',
  templateUrl: './modal-art.component.html',
  styleUrls: ['./modal-art.component.scss'],
})
export class ModalArtComponent implements OnInit {
  
  @Input() usuario;
  @Input() codigo;
  @Input() articulo;
  @Input() sede;
  public datosFormulario = new FormData();
public nombreArchivo = '';
public URLPublica = '';
public porcentaje = 0;
public finalizado = false;
public mensajeArchivo = 'No hay un archivo seleccionado';
  existenciaGlobal = 0;
  colores = [
    {nombre: 'Amarillo', existencia: 2},
    {nombre: 'Rojo', existencia: 2},
    {nombre: 'Azul', existencia: 2},
    {nombre: 'Verde', existencia: 2},
  ]
   
   private subscriptions: Subscription[] = [];


    constructor(private modal: ModalController, 
                private loadingController: LoadingController, 
                private articuloService: ArticulosService,
                private afStorage: AngularFireStorage,
                public datosService: DatosService,
                private alertController: AlertController,
               ) { }
  
   async ngOnInit() {
  
    }

    ionViewDidEnter(){
      //this.calcularExistencias();
    }
  
  
    cerrar(){
     
      this.modal.dismiss();
      this.subscriptions.forEach(item => {
        item.unsubscribe();
      });
    }
  
    async guardar(){
    
      const loading = await this.loadingController.create({
        message: 'Cargando....',
        mode: 'ios'
      });
      await loading.present();
      this.articuloService.updateArticulo(this.articulo);
      this.modal.dismiss();
      loading.dismiss();
    }

    async guardarSinCerrar(){
    
      const loading = await this.loadingController.create({
        message: 'Cargando....',
        mode: 'ios'
      });
      await loading.present();
      this.articuloService.updateArticulo(this.articulo);
      loading.dismiss();
    }



    async nuevoColor(datos){
      let nuevo = {
        color: datos.color,
        existencia: datos.existencias,
      }
      if(this.articulo.ar_colores){
        this.articulo.ar_colores.push(nuevo);
      }
      else{
        this.articulo.ar_colores = [];
        this.articulo.ar_colores.push(nuevo);
      }
      

  }


  async alertaColor() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Nuevo color',
      message: 'Ingrese datos del nuevo color',
      inputs: [
        {
          name: 'color',
          type: 'text',
          placeholder: 'Color',
          
        },
        {
          name: 'existencias',
          type: 'text',
          placeholder: 'Existencias'
          
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
         
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
         
          handler: (data) => {
           if(data.color && data.existencias){
            this.nuevoColor(data);
           }
          }
        }
      ]
    });

    await alert.present();
  }
 
  async alertaTalla() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Agregar Talla',
      message: 'Ingrese la nueva talla',
      inputs: [
        {
          name: 'talla',
          type: 'text',
          placeholder: 'Talla',
          
        },
        {
          name: 'existencias',
          type: 'number',
          placeholder: 'Existencias'
          
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
         
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
         
          handler: (data) => {
           if(data.talla && data.existencias){
            this.nuevasTallas(data);
           }
          }
        }
      ]
    });

    await alert.present();
  }
    
  // async calcularExistencias(){
  //   this.articulo.ar_colores.forEach(color => {
  //     this.existenciaGlobal += color.existencia * 1;
  //   });
  // }
  async selectFile(event){
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
       await this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        this.subirArchivo();
      }
    } else {
      this.mensajeArchivo = 'No hay un archivo seleccionado';
    }
   
  }

  async selectFile2(event){
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
       await this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        this.subirFotos();
      }
    } else {
      this.mensajeArchivo = 'No hay un archivo seleccionado';
    }
   
  }
  
  async subirArchivo() {
    
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Subiendo archivo...',
  
    });
    await loading.present()
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.afStorage.ref('Prendas/' + this.articulo.ar_nombre);
    await referencia.put(archivo);
    referencia.getDownloadURL().subscribe(data =>{
      if(data){
        this.articulo.ar_img = data;
      }
    });
    this.limpiarCarga();
    loading.dismiss();
  }

  async subirFotos() {
    
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Subiendo archivo...',
  
    });
    await loading.present()
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.afStorage.ref('Prendas/' + this.articulo.ar_nombre + '-' + (this.articulo.ar_imgs.length + 1));
    await referencia.put(archivo);
    referencia.getDownloadURL().subscribe(data =>{
      if(data){
        let img = {
          url: data,
        }
        this.articulo.ar_imgs.push(img);
        this.guardarSinCerrar();
      }
    });
    this.limpiarCarga();
    loading.dismiss();
  }


 
  
  limpiarCarga(){
    this.mensajeArchivo = 'No hay un archivo seleccionado';
    this.nombreArchivo = '';
    this.datosFormulario.delete('archivo');
  
  }

  borrarImg(img){
    let index = this.articulo.ar_imgs.findIndex(i => i.url == img.url);
    this.articulo.ar_imgs.splice(index,1);
    console.log(this.articulo.ar_imgs);
    this.guardarSinCerrar();
    this.borrarImagen(img.url);
  }

  async borrarImagen(link) {
    
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'borrando archivo...',
  
    });
    await loading.present()

    let referencia = this.afStorage.storage.refFromURL(link);
    await referencia.delete();
    loading.dismiss();
  }
  async borrar(c){
    let index = this.articulo.ar_tallas.findIndex(t => t.talla == c.talla);
    this.articulo.ar_tallas.splice(index,1);
  }


  
  async nuevasTallas(datos){
    let nuevo = {
      talla: datos.talla,
      existencia: datos.existencias * 1,
    }
    if(this.articulo.ar_tallas){
      this.articulo.ar_tallas.push(nuevo);
    }
    else{
      this.articulo.ar_tallas = [];
      this.articulo.ar_tallas.push(nuevo);
    }
    

}
    
  }
  