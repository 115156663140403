import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from 'angularfire2/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { ThrowStmt } from '@angular/compiler';
import { Articulo } from '../models/articulo';
import { Pedido } from '../models/pedido';
@Injectable({
  providedIn: 'root'
})
export class PedidoService {
  private pedidosCollection: AngularFirestoreCollection<Pedido>;

  private pedidos: Observable<Pedido[]>;


  constructor(public db: AngularFirestore) {
   
    this.pedidosCollection = db.collection<Pedido>('PEDIDOS', (ref)=>
    ref.orderBy('fecha', 'desc'));

    this.pedidos = this.pedidosCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a:any) => {
          const data = a.payload.doc.data();
          const key = a.payload.doc.id;
          return { key, ...data };
        });
      })
    );
  }

 

  getAll() {
    this.pedidos = this.db.collection<Pedido>('PEDIDOS', (ref)=>
    ref.orderBy('fecha', 'desc')).valueChanges();
    return this.pedidos;
  }

  getId(id) {
    this.pedidosCollection = this.db.collection<Pedido>('PEDIDOS', (ref)=>
    ref.orderBy('fecha', 'desc'));
    return this.pedidosCollection.doc<Pedido>(id).valueChanges();
  }

  getXfecha(inicio, fin) {
    this.pedidosCollection = this.db.collection<Pedido>('PEDIDOS', (ref)=>
    ref.startAfter(inicio).endBefore(fin));
    return this.pedidos;
  }

  getPedidos(fechaInicio, fechaFin) {
    this.pedidos = this.db.collection<Pedido>('PEDIDOS', ref =>
      ref.orderBy('fecha', 'asc').startAt(fechaInicio).endAt(fechaFin)).valueChanges();
    return this.pedidos;
   
  }

  getPedidosUsuario(usuario) {
    this.pedidos = this.db.collection<Pedido>('PEDIDOS', ref =>
      ref.where('telefono','==',usuario).orderBy('fecha', 'asc')).valueChanges();
    return this.pedidos;
   
  }
  

  update(Cia: Pedido, id: string) {
    this.pedidosCollection = this.db.collection<Pedido>('PEDIDOS', (ref)=>
    ref.orderBy('fecha', 'desc'));
    return this.pedidosCollection.doc(id).update(Cia);
  }

  add(Cia) {
    this.pedidosCollection = this.db.collection<any>('PEDIDOS', (ref)=>
    ref.orderBy('fecha', 'desc'));
    Cia.codigo = this.db.createId();
    return this.pedidosCollection.doc(Cia.codigo).set(Cia);
  }

  removeUsuario(id) {
    this.pedidosCollection = this.db.collection<Pedido>('PEDIDOS', (ref)=>
    ref.orderBy('fecha', 'desc'));
    return this.pedidosCollection.doc(id).delete();
  }
}