import { Component, Input, OnInit, ViewChild  } from '@angular/core';
import { IonInfiniteScroll, ModalController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { NavController, LoadingController, AlertController, PopoverController, ActionSheetController  } from '@ionic/angular';
import { ArticulosService } from '../../services/articulos.service';
import { Articulo } from 'src/app/models/articulo';
import { DatosService } from 'src/app/services/datos.service';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-articulos',
  templateUrl: './articulos.component.html',
  styleUrls: ['./articulos.component.scss'],
})
export class ArticulosComponent implements OnInit {
@Input() user;
@Input() barras;
@Input() sede;

public datosFormulario = new FormData();
public nombreArchivo = '';
public URLPublica = '';
public porcentaje = 0;
public finalizado = false;
public mensajeArchivo = 'No hay un archivo seleccionado';

colores = [
  {nombre: 'Amarillo', existencia: 2},
  {nombre: 'Rojo', existencia: 2},
  {nombre: 'Azul', existencia: 2},
  {nombre: 'Verde', existencia: 2},
]
articulo = {

  ar_barras: '',
  ar_categoria: '',
  ar_codigo: '',
  ar_existencia: 0,
  ar_nombre: '',
  ar_porc_iva: 0,
  ar_precio: 0,
  ar_costo: 0,
  ar_colores: [],
  ar_talla: '',
  ar_tallas: [],
  ar_tipo_talla: '',
  ar_ingreso: new Date().toISOString(),
  ar_img: '',
  ar_imgs: []
}
private subscriptions: Subscription[] = [];

  constructor( private articuloServ: ArticulosService,
               private modal: ModalController,
               private alertController: AlertController,
               public datosService: DatosService,
               private loadingCtrl: LoadingController,
               private afStorage: AngularFireStorage,
             
              
             ) { }

  async ngOnInit() {



  }


  unSubs(){
    this.subscriptions.forEach(data =>{
      data.unsubscribe();
    })
  }

  cerrar(){
    this.modal.dismiss();
    this.unSubs();
  }

  async guardar(){
    const loading = await this.loadingCtrl.create({
      message: 'Cargando....',
      mode: 'ios'
    });
    await loading.present();
    this.articuloServ.addArticulo(this.articulo);
    loading.dismiss();
    this.modal.dismiss();
   }


   async alertaTalla() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Agregar Talla',
      message: 'Ingrese la nueva talla',
      inputs: [
        {
          name: 'talla',
          type: 'text',
          placeholder: 'Talla',
          
        },
        {
          name: 'existencias',
          type: 'number',
          placeholder: 'Existencias'
          
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
         
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
         
          handler: (data) => {
           if(data.talla && data.existencias){
            this.nuevasTallas(data);
           }
          }
        }
      ]
    });

    await alert.present();
  }

  async alertaError() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'ios',
      header: 'Error al leer codigo',
      message: 'Favor intente de nuevo',
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  async nuevoColor(datos){
      let nuevo = {
        color: datos.color,
        existencia: parseInt(datos.existencias),
      }
      if(this.articulo.ar_colores){
        this.articulo.ar_colores.push(nuevo);
      }
      else{
        this.articulo.ar_colores = [];
        this.articulo.ar_colores.push(nuevo);
      }
      

  }



  async alertaColor() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Nuevo color',
      message: 'Nombre',
      inputs: [
        {
          name: 'color',
          type: 'text',
          placeholder: 'Color',
          
        },
        {
          name: 'existencias',
          type: 'text',
          placeholder: 'Existencias'
          
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
         
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Aceptar',
         
          handler: (data) => {
           if(data.color && data.existencias){
            this.nuevoColor(data);
           }
          }
        }
      ]
    });

    await alert.present();
  }
 

  async selectFile(event){
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        this.mensajeArchivo = `Archivo preparado: ${event.target.files[i].name}`;
        this.nombreArchivo = event.target.files[i].name;
        this.datosFormulario.delete('archivo');
       await this.datosFormulario.append('archivo', event.target.files[i], event.target.files[i].name);
        this.subirArchivo();
      }
    } else {
      this.mensajeArchivo = 'No hay un archivo seleccionado';
    }
   
  }
  
  async subirArchivo() {
    
    const loading = await this.loadingCtrl.create({
      cssClass: 'my-custom-class',
      message: 'Subiendo archivo...',
  
    });
    await loading.present()
    let archivo = this.datosFormulario.get('archivo');
    let referencia = this.afStorage.ref('Prendas/' + this.articulo.ar_nombre);
    await referencia.put(archivo);
    referencia.getDownloadURL().subscribe(data =>{
      if(data){
        this.articulo.ar_img = data;
      }
    });
    this.limpiarCarga();
    loading.dismiss();
  }
  
  limpiarCarga(){
    this.mensajeArchivo = 'No hay un archivo seleccionado';
    this.nombreArchivo = '';
    this.datosFormulario.delete('archivo');
  
  }

  async nuevasTallas(datos){
    let nuevo = {
      talla: datos.talla,
      existencia: datos.existencias * 1,
    }
    if(this.articulo.ar_tallas){
      this.articulo.ar_tallas.push(nuevo);
    }
    else{
      this.articulo.ar_tallas = [];
      this.articulo.ar_tallas.push(nuevo);
    }
    

}

async borrar(c){
  let index = this.articulo.ar_tallas.findIndex(t => t.talla == c.talla);
  this.articulo.ar_tallas.splice(index,1);
}

  
}