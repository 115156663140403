import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatosService } from 'src/app/services/datos.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
})
export class ContactoComponent implements OnInit {

  constructor(private modal: ModalController,
              private datosServ: DatosService,
              private iab: InAppBrowser) { }

  ngOnInit() {
    console.log(this.datosServ.links)
  }


  cerrar(){
    this.modal.dismiss();
  }

  whats(){
    this.iab.create(this.datosServ.links.whatsapp);
  }
  insta(){
    this.iab.create(this.datosServ.links.insta);
  }
  facebook(){
    this.iab.create(this.datosServ.links.facebook);
  }

}
