import { Component, Input, OnInit } from '@angular/core';
import { ModalAgregarComponent } from '../modal-agregar/modal-agregar.component';
import { ModalController, LoadingController } from '@ionic/angular';
import { CarritoService } from '../../services/carrito.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatosService } from 'src/app/services/datos.service';
@Component({
  selector: 'myApp-prenda-cliente',
  templateUrl: './prenda-cliente.component.html',
  styleUrls: ['./prenda-cliente.component.scss'],
})
export class PrendaClienteComponent implements OnInit {
@Input() prenda;
@Input() celular;
modificados = [];
cantidad = 1;
imgSelected = '';

  constructor(private modal2: ModalController, 
              private loadingCtrl: LoadingController,
              private datosService: DatosService,
              private iab: InAppBrowser,
              private modal: ModalController,
              private carritoService: CarritoService) { }

  ngOnInit() {

    this.imgSelected = this.prenda.ar_img;
  }

  pinFormatter(value: number) {
    return `${value}%`;
  }

  cambiarPrenda(img){
    this.imgSelected = img;
  }

  async agregarCarrito(){
    const loading = await this.loadingCtrl.create({
      message: 'Agregando producto....',
      mode: 'ios'
    });
    await loading.present();
    let nuevo = {
      ar_barras: this.prenda.ar_barras,
      ar_categoria: this.prenda.ar_categoria,
      ar_codigo: this.prenda.ar_codigo,
      ar_existencia: this.prenda.ar_existencia,
      ar_nombre: this.prenda.ar_nombre,
      ar_porc_iva: this.prenda.ar_porc_iva, 
      ar_precio: this.prenda.ar_precio,
      ar_costo: this.prenda.ar_costo,
      ar_colores: this.prenda.ar_colores,
      ar_talla: this.prenda.ar_talla,
      ar_tipo_talla: this.prenda.ar_tipo_talla,
      ar_ingreso: this.prenda.ar_ingreso,
      ar_img: this.prenda.ar_img,
      ar_imgs: this.prenda.ar_imgs,
      ar_cantidad: this.cantidad
    }
   
    this.carritoService.carrito.push(nuevo);
    //this.rebajarCantidades(prenda, cantidad, color);
    this.totalizar();
    this.modal.dismiss();
    loading.dismiss();
    

  }





  async totalizar(){
    this.carritoService.total = 0;
    this.carritoService.carrito.forEach(data =>{
      this.carritoService.total += data.ar_precio * data.ar_cantidad;
    })
  }

  contacto(){
    this.iab.create(this.datosService.links.whatsapp);
  }
  cerrar(){
    this.modal.dismiss();
  }


  async agregar() {

    let ccsClass = '';
    if(this.celular){
      ccsClass = 'class-modal';
    }
    else{
      ccsClass = 'modal-reserva'
    }
    const modal = await this.modal2.create({
      component: ModalAgregarComponent,
      backdropDismiss: false,
      cssClass: ccsClass,
      componentProps: {
        articulo: this.prenda,
      }
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
        if(data.color && data.cantidad){
          console.log(data.color);
          //this.agregarPrendas(this.prenda, data.color, data.cantidad);
          this.modal.dismiss();
        }
    }
  }

  async rebajarCantidades(art, cantidad, color){
    let index = art.ar_colores.findIndex(c => c.color == color);
      art.ar_colores[index].existencia -= cantidad;
      console.log(art);
      if(this.modificados.length == 0){
        this.modificados.push(art);
      }
      else{
        let index = this.modificados.findIndex(articulo => articulo.ar_codigo == art.ar_codigo);
        if(index >= 0){
          this.modificados[index] = art;
        }
        else{
          this.modificados.push(art);
        }
   
      }
      console.log(this.modificados)
    
  }

}
