import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';

declare var google;
@Component({
  selector: 'myApp-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

  constructor(
    private loadingController: LoadingController,
    private geolocation: Geolocation,
    private activatedRoute: ActivatedRoute,
    private nav: NavController,
    private modal: ModalController,
    private alert: AlertController,


  ) { }
  gratis = '0';
  sinBoton = false;
  gpsComercio = '9.936714,-84.072234'
  envio = 0;
  distancia = false;
  comerc: string;
  observacion = ''
  empresa: string;
  map:any;
  markers = [];
  gps: string;
  lat: number
  long: number
  costoXkm = 500;


  async ngOnInit() {
   
  }

  ionViewWillEnter() {

    this.loadMap2();
    this.medir()
  }



  async loadMap2(){
    
    const loading = await this.loadingController.create();
    loading.present();
    let myLatLng: any
    await this.geolocation
    .getCurrentPosition()
    .then((resp) => {
       myLatLng = {
        lat: resp.coords.latitude,
        lng: resp.coords.longitude,
        
      };
     
      const mapEle: HTMLElement = document.getElementById('map');
      this.map = new google.maps.Map(mapEle, {
        center: myLatLng,
        zoom: 15,
        
      });
      loading.dismiss();
      const marker = new google.maps.Marker({
        position: {
          lat: myLatLng.lat,
          lng: myLatLng.lng
        },
        icon: '',
        zoom: 9,
        map: this.map,
        title: 'Ubicacion Actual'
      });
      this.markers.push(marker);
    })
    .catch((error) => {
      let gps = this.gpsComercio;
      console.log('Ubicacion del Comercio ' + gps)
      let splitted  = gps.split(',')
      let Lt = splitted[0]
      let Ln = splitted[1]
    
      let lat2 = Lt;
      let long2 =Ln; 
      myLatLng = {
        lat: parseFloat(lat2),
        lng: parseFloat(long2),
        
      };
      const mapEle: HTMLElement = document.getElementById('map');
      this.map = new google.maps.Map(mapEle, {
        center: myLatLng,
        zoom: 15,
        
      });
      this.errorGps();
      loading.dismiss();
    
    });


   
   

    this.lat = myLatLng.lat
    this.long = myLatLng.lng
    this.medir();
    this.gps = myLatLng.lat + ',' + myLatLng.lng;
    // this.markers.push(marker);
    
    
    google.maps.event.addListener(this.map, 'click', (data) => {
      console.log(data.latLng)
      
      this.addMarker(data.latLng, this.map);
      
    });

  }

 async addMarker(location, map) {
  this.sinBoton = false;
  var lat = location.lat();
  var lng = location.lng();
  this.lat = lat
  this.long = lng
  var gps = lat+','+lng;
  this.deleteMarkers();
  var marker = new google.maps.Marker({
    position: location,
    zoom: 17,
    map: map,
    icon: ''
    
    });
  
   this.map.panTo(location);
    this.markers.push(marker);
    
    this.gps = gps
    console.log('Gps de Pedido ' + this.gps)
    this.medir();
  }

  clearMarkers() {
    this.setMapOnAll(null);
  }
  deleteMarkers() {
    this.clearMarkers();
    this.markers = [];
  }
   setMapOnAll(map) {
    for (var i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }

}

guardar(){
  this.modal.dismiss({
    gps: this.gps,
    envio: this.envio
  })
}



//////////////////////// calcular distancias
medir(){


  let gps = this.gpsComercio;
  console.log('Ubicacion del Comercio ' + gps)
  let splitted  = gps.split(',')
  let Lt = splitted[0]
  let Ln = splitted[1]

  let lat2 = Lt;
  let long2 =Ln; 
  this.calculateDistance(this.lat,parseFloat(lat2),this.long,parseFloat(long2));
}


calculateDistance(lat1:number,lat2:number,long1:number,long2:number){
  let p = 0.017453292519943295;    // Math.PI / 180
  let c = Math.cos;
  let a = 0.5 - c((lat1-lat2) * p) / 2 + c(lat2 * p) *c((lat1) * p) * (1 - c(((long1- long2) * p))) / 2;
  let dis = (12742 * Math.asin(Math.sqrt(a))); 

  if(this.gratis != '0'){
    if(dis<=2){
      this.envio = 0
      this.distancia = false
    }
    if(dis>2 && dis<4){
      this.envio = 1500
      this.distancia = false
    }
    if(dis>=4 && dis<5){
      this.envio = 2000
      this.distancia = false
    }
    if(dis>=5 && dis<6){
      this.envio = 2500
      this.distancia = false
    }
    if(dis>=6 && dis<7){
      this.envio = 3000
      this.distancia = false
    }
    if(dis>=7){
      
      this.distancia = true
    }
  }else{
    // if(dis<=2){
    //   this.envio = 1000
    //   this.distancia = false
    // }
    // if(dis>2 && dis<4){
    //   this.envio = 1500
    //   this.distancia = false
    // }
    // if(dis>=4 && dis<5){
    //   this.envio = 2000
    //   this.distancia = false
    // }
    // if(dis>=5 && dis<6){
    //   this.envio = 2500
    //   this.distancia = false
    // }
    // if(dis>=6 && dis<7){
    //   this.envio = 3000
    //   this.distancia = false
    // }
    if(dis<1){
      this.envio = this.costoXkm;
      this.distancia = false;
    }

    if(dis<7 && dis>1){
      this.envio = dis * this.costoXkm;
      this.distancia = false;
    }
    if(dis>=7){
      
      this.distancia = true;
      
    }
  }
  
  
  
  console.log("El costo del express es de " + this.envio)
  return dis;

}
async errorGps() {
  const alert = await this.alert.create({
    cssClass: 'my-custom-class',
    mode: 'ios',
    header: 'Selección de Ubicación Gps manual',

    message: 'Mueve el mapa y selecciona tu ubicación de entrega, actualmente los servicios de ubicación Gps se encuentran deshabilitados para mostrar tu ubicación actual cambia las configuraciones del app',
    buttons: ['Aceptar'],
  });

  await alert.present();
  this.sinBoton = true;
}

}
