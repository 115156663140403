import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from 'angularfire2/firestore';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { ThrowStmt } from '@angular/compiler';
import { Articulo } from '../models/articulo';
import { DatosService } from './datos.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private articuloCollection: AngularFirestoreCollection<any>;
  private articulos: Observable<any[]>;

  private catsCollection: AngularFirestoreCollection<any>;
  private cats: Observable<any[]>;

  constructor( private db: AngularFirestore,
               private ciaServ: DatosService ) {

   
    
  }

  rebuild() {
   
  }

 
  
  getUser( id: string ) {
    this.articuloCollection = this.db.collection<any>('USUARIOS');
    return this.articuloCollection.doc<any>(id).valueChanges();
  }

  addUser( articulo) {

    this.articuloCollection = this.db.collection<any>('USUARIOS');
    return this.articuloCollection.doc(articulo.ar_codigo).set(articulo);
  }


  addCoinsa(user:any) {

    this.articuloCollection = this.db.collection<any>('USUARIOS');
    return this.articuloCollection.doc(user.idUsuario).set(user);
  }
 

}
