import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PedidoService } from 'src/app/services/pedido.service';

@Component({
  selector: 'app-detalle-pedido',
  templateUrl: './detalle-pedido.component.html',
  styleUrls: ['./detalle-pedido.component.scss'],
})
export class DetallePedidoComponent implements OnInit {

  constructor(
              private modalController: ModalController,
              private pedidoServ: PedidoService,) { }
@Input() pedido;
@Input() admin;
  ngOnInit() {}


  cerrar(){
    this.modalController.dismiss();
  }

  guardar(){
    this.pedidoServ.update(this.pedido, this.pedido.codigo);
    this.modalController.dismiss();
  }

}
