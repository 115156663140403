import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CarritoService } from 'src/app/services/carrito.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'myApp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  celular = false;
  telefono = '';
  password = '';
  private subscriptions: Subscription[] = [];
  constructor(
              private userServ: UserService,
              private cartService: CarritoService,
              private modal: ModalController,
  ) { }

  ngOnInit() {}


  unsubs(){
    this.subscriptions.forEach(sub =>{
      sub.unsubscribe();
    })
  }

  cerrar(){
    this.modal.dismiss();
  }
  async obtenerUsuario(){
    this.subscriptions.push(
      this.userServ.getUser(this.telefono).subscribe(data =>{
        if(data){
          if(this.password == data.password){
            this.cartService.usuario = data;
            this.modal.dismiss();
            this.unsubs();
            alert('Inicio de sesion exitoso!');
            
          }
          else{
            alert('Contraseña incorrecta!')
          }
          
        }
        else{
          alert('Usuario no existe!');
        }
      })
    )
  }
}
