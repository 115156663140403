import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StorageService } from './services/storage.service';
import { DatosService } from './services/datos.service';
import { Subscription } from 'rxjs';
import { CarritoService } from './services/carrito.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
 
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: StorageService,
    private nav: NavController,
    private datosService: CarritoService,

  ) {
    this.initializeApp();
    
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      this.splashScreen.hide();
    this.verificar();
    });
  }

  
 

  


  verificar(){
    if(this.datosService.usuario){

    }
    else{
      this.nav.navigateRoot(['home']);
    }
  }

 
}
